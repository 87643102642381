<template>
    <div class="pagelogin">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <div class="loginbox">
            <div class="formbox">
                <div class="tabbox">
                    <el-tabs v-model="tabactive" type="card">
                        <el-tab-pane label="账号密码登录" name="1">
                            <div class="clear"></div>
                            <div class="inputbox">
                                <div class="ibox">
                                    <input type="text" v-model="accountname" placeholder="请输入账号/卡号/手机号" maxlength="20" />
                                </div>
                                <div class="ibox">
                                    <input v-model="accountpwd" :type="pwdtype" placeholder="请输入密码" maxlength="20" />
                                    <i v-if="pwdstatus" class="iconfont" @click.prevent="pwdbtn(false)">&#xe61b;</i>
                                    <i v-else class="iconfont" @click.prevent="pwdbtn(true)">&#xe61c;</i>
                                </div>
                                <div class="ibox">
                                    <div class="submitbtn" @click.prevent="login_acc()">登&nbsp;&nbsp;录</div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="验证码登录/注册" name="2">
                            <div class="clear"></div>
                            <div class="inputbox">
                                <div class="ibox">
                                    <input type="text" v-model="telnumber" placeholder="请输入手机号" maxlength="11" />
                                </div>
                                <div class="ibox">
                                    <input class="code" v-model="telcode" placeholder="请输入验证码" maxlength="6" />
                                    <div class="codebtn" v-if="isSend">{{ time }} s</div>
                                    <div class="codebtn" v-else @click="sendcode()">发送验证码</div>
                                </div>
                                <div class="ibox">
                                    <div class="submitbtn" @click.prevent="login_tel()">登 录 / 注 册</div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="txtbox">
                        <span class="txt toreg">
                            <el-checkbox v-model="agreement">我已阅读并接受</el-checkbox><a class="xy"
                                @click.prevent="useragreement()">《用户协议》</a>和<a class="xy"
                                @click.prevent="privacypolicy()">《隐私政策》</a>
                        </span>
                        <span class="txt" @click.prevent="forgetpwd()"><span class="getpwd">忘记密码？</span></span>
                    </div>
                    <div class="otherlogin">
                        <div class="otit"></div>
                    </div>
                </div>
                <div class="tipsbox" :class="tipsshowstyle"><span><i class="iconfont">&#xe64d;</i></span>{{ tipsshowtxt }}
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 完善信息 -->
        <div class="layer_agreement" :class="layerclass" v-if="infoshow">
            <div class="layerbox" :class="layerboxclass">
                <div class="layercon">
                    <div class="tit">完善信息</div>
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                        <div class="info_box">
                            <div class="info_title"><i></i>验证手机号</div>
                            <div class="info_input">
                                <input type="text" v-model="userinfo.usertel" placeholder="请输入手机号" maxlength="11" />
                            </div>
                            <div class="info_input">
                                <input class="code" v-model="userinfo.telcode" placeholder="请输入验证码" maxlength="6" />
                                <div class="cbtn" v-if="isSend">{{ time }} s</div>
                                <div class="cbtn" v-else @click="sendcodeuser()">发送验证码</div>
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="info_title"><i></i>完善个人信息</div>
                            <el-form :model="userRuleForm" :rules="userRules" ref="userRuleForm" label-width="120px" class="info_form">
                                <el-form-item style="margin-bottom: 0px;">
                                    <span class="tips"><i class="iconfont">&#xe67b;</i>注：姓名、就读所在地、就读学校保存后不允许修改</span>
                                </el-form-item>
                                <el-form-item label="姓名" prop="StudentName">
                                    <el-input v-model="userRuleForm.StudentName" placeholder="请输入姓名" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="性别" prop="Sex" style="margin-bottom: 10px;">
                                    <el-radio-group v-model="userRuleForm.Sex">
                                        <el-radio :label="true" >男</el-radio>
                                        <el-radio :label="false">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="就读所在地" prop="OptionsValue">
                                    <el-cascader v-model="userRuleForm.OptionsValue" :options="AreaOptions" @change="handleChangeArea" clearable style="width:100%"></el-cascader>
                                </el-form-item>
                                <el-form-item label="就读学校" prop="SchoolValue">
                                    <el-select v-model="userRuleForm.SchoolValue" placeholder="请选择" clearable style="width:100%" @change="handleChangeSchool">
                                        <el-option v-for="item in SchoolData" :key="item.Id" :label="item.SchoolName" :value="item.Id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="学校名称" v-if="otherSchool||userRuleForm.SchoolValue=='000'">
                                    <el-input v-model="userRuleForm.SchoolName" placeholder="请输入学校名称" clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="info_box">
                            <div class="info_title"><i></i>完善高考信息</div>
                            <el-form :model="examRuleForm" :rules="examRules" ref="examRuleForm" label-width="120px" class="info_form">
                                <el-form-item style="margin-bottom: 0px;">
                                    <span class="tips"><i class="iconfont">&#xe67b;</i>注：高考省份、高考年份、选科保存后不允许修改，预估分数在模拟填报期间可任意填写</span>
                                </el-form-item>
                                <el-form-item label="高考省份" prop="ExamProvinceId">
                                    <el-select v-model="examRuleForm.ExamProvinceId" placeholder="请选择" clearable style="width:100%"
                                        @change="handleChangeExamArea">
                                        <el-option v-for="item in ExamProvinceData" :key="item.ProvinceId" :label="item.ProvinceName"
                                            :value="item.ProvinceId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="高考年份" prop="ExamYear">
                                    <el-date-picker v-model="examRuleForm.ExamYear" type="year" placeholder="选择年" value-format="yyyy" clearable
                                        style="width:100%" @change="handleChangeYear"></el-date-picker>
                                </el-form-item>
                                <el-form-item label="选科">
                                    <span class="tips" v-if="isCheack == false"><i class="iconfont">&#xe67b;</i>请先选择“高考省份”</span>
                                    <div v-else>
                                        <div class="subject" v-for="(item, index) in ExamData.RuleList" :key="index">
                                            <span>{{ item.SelectExamTitle }}</span>
                                            <div class="subject-item">
                                                <el-checkbox-group v-model="item.selected" :max="item.MaxSelectCount">
                                                    <el-checkbox v-for="subItem in item.SelectExamSubjects" :key="subItem"
                                                        :label="subItem"></el-checkbox>
                                                </el-checkbox-group>
                                            </div>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="预估分数" prop="Score">
                                    <el-input v-model="examRuleForm.Score" maxlength="3" :placeholder="`${ProvinceObj.ScoreLow||'请输入分数'}${ProvinceObj.ScoreHight?'-'+ProvinceObj.ScoreHight:''}`"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-scrollbar>
                    <div class="btn1" @click.prevent="userinfohide()">返 回</div>
                    <div class="btn2" @click.prevent="userinfosubmit()">提 交</div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 用户协议 -->
        <div class="layer_agreement" :class="layerclass" v-if="agreementshow">
            <div class="layerbox" :class="layerboxclass">
                <div class="layercon">
                    <div class="tit">用户协议</div>
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                        <div class="content" v-html="agreementhtml"></div>
                    </el-scrollbar>
                    <div class="back" @click.prevent="agreementhide()">返回</div>
                </div>
            </div>
        </div>
        <!-- 隐私政策 -->
        <div class="layer_agreement" :class="layerclass" v-if="privacyshow">
            <div class="layerbox" :class="layerboxclass">
                <div class="layercon">
                    <div class="tit">隐私政策</div>
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                        <div class="content" v-html="privacyhtml"></div>
                    </el-scrollbar>
                    <div class="back" @click.prevent="privacyhide()">返回</div>
                </div>
            </div>
        </div>

        <!-- 忘记密码 -->
        <ForgetPwd ref="refforgetpwd"></ForgetPwd>

        <!-- 拼图滑动验证 -->
        <PuzzleVerification ref="verificationShow" :Mobile="telnumber" :onSuccess="slideVerifySuccess"></PuzzleVerification>
        <PuzzleVerification ref="userVerificationShow" :Mobile="userinfo.usertel" :onSuccess="userSlideVerifySuccess"></PuzzleVerification>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import PuzzleVerification from "@/components/PuzzleVerification.vue";
import ForgetPwd from "./components/forgetpwd/forgetpwd.vue";
import { getStore, setStore, removeStore } from "../utils/utils";
import API from "../api/config";
export default {
    components: {
        PHeader,
        PFooter,
        ForgetPwd,
        PuzzleVerification
    },
    data() {
        return {
            pwdstatus: true,
            pwdtype: 'password',
            tabactive: "1",
            accountname: '',
            accountpwd: '',
            telnumber: '',
            telcode: '',
            agreement: false,
            tipsshow: false,
            tipsshowstyle: '',
            tipsshowtxt: '',
            isSend: false,
            time: 60,
            timer: null,
            layerclass: '',
            layerboxclass: '',
            agreementshow: false,
            agreementhtml: "",
            privacyshow: false,
            privacyhtml: '',
            infoshow:false,
            userinfo:{
                usertel:'',
                telcode:''
            },
            otherSchool:false,
            AreaOptions:[],
            SchoolData:[],
            ExamProvinceData: [],
            ExamData:[],
            isCheack:false,
            ProvinceObj: {},
            userRuleForm:{
                StudentName: '',
                Sex:null,
                SchoolName:'',
                OptionsValue:'',
                SchoolValue:''
            },
            userRules: {
                StudentName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Sex: [
                    { required: true, message: '请选择性别', trigger: 'blur' }
                ],
                OptionsValue: [
                    { required: true, message: '请选择就读所在地', trigger: 'change' }
                ],
                SchoolValue: [
                    { required: true, message: '请选择就读学校', trigger: 'change' }
                ],
            },
            examRuleForm:{
                ExamProvinceId: '',
                ExamYear: '',
                SubjectType: '',
                SelectSubjectList: [
                    {
                        SelectExamSubjects: []
                    },
                    {
                        SelectExamSubjects: []
                    }
                ],
                Score: ''
            },
            examRules: {
                ExamProvinceId: [
                    { required: true, message: '请选择高考省份', trigger: 'change' }
                ],
                ExamYear: [
                    { required: true, message: '请选择高考年份', trigger: 'change' }
                ],
                Score: [
                    { required: true, message: '请输入预估分数', trigger: 'blur' }
                ],
            },
            queryInfo: {}
        }
    },
    mounted(){
        let area = getStore('ProvinceList');
        if (area != null && area != undefined && area != '') {
            this.ExamProvinceData = JSON.parse(area);
        }
        this.GetAreaOptions();// 获取省市县数据
        // this.userinfoshow();
        // this.examRuleForm.ExamYear = getStore('WishYear')
        
        let WY = getStore('WishYear')
        if (WY) {
            WY = JSON.parse(WY)

            if (typeof WY == 'object') {
                WY = WY.data
            }
            this.examRuleForm.ExamYear = String(WY)
        }
    },
    methods: {
        // 获取省市县数据
        GetAreaOptions(){
            API.Options.GetAreaOptions().then(res => {
                if(res.code===0){
                    this.$nextTick(()=>{
                        res.data.forEach(p=>{
                            p.value = p.Id;
                            p.label = p.Name;
                            p.ChildrenList.forEach(c=>{
                                c.value = c.Id;
                                c.label = c.Name;
                                c.ChildrenList.forEach(d=>{
                                    d.value = d.Id;
                                    d.label = d.Name;
                                });
                                c.children = c.ChildrenList;
                            });
                            p.children = p.ChildrenList;
                        });
                        this.AreaOptions = res.data;
                    })
                }
            });
        },
        // 省市县数据切换
        handleChangeArea(value){
            this.userRuleForm.OptionsValue = value;
            this.otherSchool = false;
            this.userRuleForm.SchoolName = '';
            this.GetSchoolOptions(value);// 获取学校数据
        },
        // 获取学校数据
        GetSchoolOptions(value){
            this.userRuleForm.SchoolValue = [];
            let query = {
                provinceId: value[0],
                cityId: value[1],
                districtId: value[2]
            }
            API.Options.GetSchoolOptions(query).then(res => {
                if(res.code===0 && res.data!=null){
                    this.SchoolData = res.data;
                    this.SchoolData.push({
                        Id: '000',
                        SchoolName: '其他'
                    })
                }
            });
        },
        // 学校数据切换
        handleChangeSchool(value){
            if (value === '000') {
                this.otherSchool = true;
                this.userRuleForm.SchoolName = '';
            }
            else{
                this.otherSchool = false;
                let obj = this.SchoolData.find(f=>f.Id==value);
                if(obj!=null){
                    this.userRuleForm.SchoolName = this.SchoolData.find(f=>f.Id==value).SchoolName;
                }
            }
        },
        // 高考省份切换
        handleChangeExamArea(value) {
            
            if (value) {
                let temp = this.ExamProvinceData.find(f=>f.ProvinceId===value)

                this.ProvinceObj = temp

                this.handleSubject(temp)
                this.isCheack = true;
            }
            else {
                this.isCheack = false;
            }
        },
        // 高考年份切换
        handleChangeYear(value) {
            if (this.examRuleForm.ExamProvinceId != '' && value != null) {
                this.isCheack = true;
            }
            else {
                this.isCheack = false;
            }
        },
        // 科类判断
        handleSubject(ProviceItem) {
            this.$set(this.ExamData, 'RuleList', [])
            if (ProviceItem.EnableSubjectType) { // 有物理历史
                this.$set(this.ExamData.RuleList, 0, {
                    SelectExamTitle: '首选',
                    MaxSelectCount: 1,
                    SelectExamSubjects: ['物理', '历史'],
                    selected: []
                })
                this.$set(this.ExamData.RuleList, 1, {
                    SelectExamTitle: '再选',
                    MaxSelectCount: ProviceItem.SubjectOptionsCount,
                    SelectExamSubjects: ProviceItem.SubjectOptions.split(','),
                    selected: []
                })
            } else {
                this.$set(this.ExamData.RuleList, 0, {
                    SelectExamTitle: '',
                    MaxSelectCount: ProviceItem.SubjectOptionsCount,
                    SelectExamSubjects: ProviceItem.SubjectOptions.split(','),
                    selected: []
                })
            }
        },
        // 显示完善信息
        userinfoshow(){
            this.layerclass='layershow';
            this.layerboxclass="layerboxshow";
            this.infoshow=true;
        },
        // 关闭完善信息
        userinfohide(){
            this.layerclass = 'layerhide';
            this.layerboxclass = "layerboxhide";
            let st = setTimeout(() => {
                this.infoshow = false;
                clearTimeout(st);
            }, 300);
        },
        // 完善信息验证码
        sendcodeuser(){
            if (!this.tipsshow) {
                if (!this.userinfo.usertel.trim()) {
                    this.$message.warning("请输入手机号！"); return false;
                }
                else {
                    let str = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if (str.test(this.userinfo.usertel.trim()) == false) {
                        this.$message.warning("手机号格式不正确！"); return false;
                    }
                }
                this.$refs.userVerificationShow.isVerificationShow = true;//显示 拼图滑动验证组件
                this.$refs.userVerificationShow.refreshImg();//刷新 拼图滑动验证组件
            }
        },
        // 完善信息提交
        userinfosubmit(){
            /**
             * {
                "TerminalType": 0,
                "ValidateType": 0,
                "Mobile": "string",
                "ValidateCode": "string",
                "StudentName": "string",
                "Sex": true,
                "ProvinceId": 0,
                "CityId": 0,
                "DistrictId": 0,
                "SchoolId": 0,
                "SchoolName": "string",
                "ExamProvinceId": 0,
                "ExamProvinceName": "string",
                "ExamYear": 0,
                "SubjectType": 1,
                "SubjectList": [
                    "string"
                ],
                "Score": 0,
                "CardNum": "string",
                "CardPassword": "string"
                }
             * 
             * 
            */
            // API.Auth.RegisterAndLogin
            if (!this.userinfo.usertel || !this.userinfo.telcode) {
                this.$message.error('请输入手机号和验证码！')
                return
            }
            this.$refs.userRuleForm.validate(valid => {
                if (valid) {
                    this.$refs.examRuleForm.validate(valid2 => {
                        if (valid2) {


                            let f = this.ExamData.RuleList.some((it, i) => {
                                if (it.selected.length!=it.MaxSelectCount) {
                                    this.$message.error('请完善选科！')
                                    return true
                                }
                            })

                            if (f) {
                                return
                            }

                            this.queryInfo.TerminalType = 0
                            this.queryInfo.ValidateType = 1
                            this.queryInfo.Mobile = this.userinfo.usertel
                            this.queryInfo.ValidateCode = this.userinfo.telcode
                            this.queryInfo.StudentName = this.userRuleForm.StudentName
                            this.queryInfo.Sex = this.userRuleForm.Sex
                            this.queryInfo.ProvinceId = this.userRuleForm.OptionsValue[0]
                            this.queryInfo.CityId = this.userRuleForm.OptionsValue[1]
                            this.queryInfo.DistrictId = this.userRuleForm.OptionsValue[2]
                            this.queryInfo.SchoolId = this.userRuleForm.SchoolValue
                            this.queryInfo.SchoolName = this.userRuleForm.SchoolName
                            this.queryInfo.ExamProvinceId = this.ProvinceObj.ProvinceId
                            this.queryInfo.ExamProvinceName = this.ProvinceObj.ProvinceName
                            this.queryInfo.ExamYear = Number(this.examRuleForm.ExamYear)

                            if (this.ExamData.RuleList) {
                                if (this.ExamData.RuleList.length==1) {

                                    this.queryInfo.SubjectType = null
                                    this.queryInfo.SubjectList = this.ExamData.RuleList[0].selected
                                }
                                if (this.ExamData.RuleList.length==2) {
                                    if (this.ExamData.RuleList[0].selected[0]=='物理') {
                                        this.queryInfo.SubjectType = 1
                                    } else if(this.ExamData.RuleList[0].selected[0]=='历史') {
                                        this.queryInfo.SubjectType = 2
                                    } else {
                                        this.queryInfo.SubjectType = null
                                    }
                                    this.queryInfo.SubjectList = this.ExamData.RuleList[1].selected
                                }
                            }
                            this.queryInfo.Score = Number(this.examRuleForm.Score)
                            this.queryInfo.CardNum = this.accountname
                            this.queryInfo.CardPassword = this.accountpwd

                            API.Personal.RegisterAndLogin(this.queryInfo).then(res => {
                                
                                if (res.Code==0) {

                                    setStore("LoginData", res.Data);

                                    this.$store.dispatch('user/getStudentInfo').then(userInfo => {

                                        removeStore('tempScoreInfo')

                                        let province = getStore('ExamProvince')
                                        if (!province) {
                                            this.$message.error('无省份数据！')
                                            return false
                                        }
                                        province = JSON.parse(province)

                                        if (userInfo.UserScore) {
                                            
                                            if (userInfo.UserScore.ExamProvinceId) {
                                                let obj = province.find(item => item.ProvinceId === userInfo.UserScore.ExamProvinceId)
                                                
                                                if (obj) {
                                                    setStore('OrgProvince', obj)
                                                } else {
                                                    this.$message.error('ExamProvinceId未更新！')
                                                }
                                            }
                                        }

                                        // this.$router.replace("/personal");
                                        API.User.CheckUserStudentComplate().then(res => {
                                            if (res.code==0) {
                                                if (res.data.IsNeedComplateBaseInfo) {
                                                    this.$router.replace("/personal");
                                                } else if (res.data.IsNeedComplateScore) {
                                                    this.$router.replace("/examinee");
                                                } else {
                                                    this.$router.replace("/");
                                                }
                                            } else {
                                                this.$router.replace("/");
                                            }
                                        })
                                    })
                                } else {
                                    this.$message.error(res.Message)
                                }
                            })
                        }
                    })
                }
            })
        },
        //登录注册发送验证码
        sendcode() {
            if (!this.tipsshow) {
                if (!this.telnumber.trim()) {
                    this.verifytips("请输入手机号！"); return false;
                }
                else {
                    let str = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if (str.test(this.telnumber.trim()) == false) {
                        this.verifytips("手机号格式不正确！"); return false;
                    }
                }
                this.$refs.verificationShow.isVerificationShow = true;//显示 拼图滑动验证组件
                this.$refs.verificationShow.refreshImg();//刷新 拼图滑动验证组件
            }
        },
        //拼图滑动验证成功的回调
        slideVerifySuccess(par) {
            this.$refs.verificationShow.isVerificationShow = false;//隐藏拼图滑动验证组件
            // 发送手机验证码
            par.ValidateType = 0;
            API.Web.SendValidateCode(par).then(res => {
                if (res.Code === 0) {
                    this.handleTimer();//倒计时
                }
                else {
                    this.verifytips(res.Message);
                }
            });
        },
        userSlideVerifySuccess(par) {
            this.$refs.userVerificationShow.isVerificationShow = false;//隐藏拼图滑动验证组件
            // 发送手机验证码
            par.ValidateType = 1;
            API.Web.SendValidateCode(par).then(res => {
                if (res.Code === 0) {
                    this.handleTimer();//倒计时
                }
                else {
                    this.$message.error(res.Message)
                }
            });
        },
        //登录验证码倒计时
        handleTimer() {
            this.isSend = true;
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                }
                else {
                    clearInterval(this.timer);
                    this.isSend = false;
                    this.time = 60;
                }
            }, 1000);
        },
        //密码显示
        pwdbtn(b) {
            this.pwdstatus = b;
            if (b) {
                this.pwdtype = "password";
            }
            else {
                this.pwdtype = "text";
            }
        },
        //账号密码 登录
        login_acc() {
            if (!this.tipsshow) {
                if (!this.accountname) {
                    this.verifytips("请输入账号/手机号！"); return false;
                }
                if (!this.accountpwd) {
                    this.verifytips("请输入密码！"); return false;
                }
                if (!this.agreement) {
                    this.verifytips("请阅读《用户协议》和《隐私政策》并勾选接受"); return false;
                }
                //登录
                API.Web.Login({ UserName: this.accountname, Password: this.accountpwd, TerminalType: 0 }).then(res => {
                    if (res.Code === 0) {
                        setStore("LoginData", res.Data);

                        this.$store.dispatch('user/getStudentInfo').then(userInfo => {

                            removeStore('tempScoreInfo')

                            let province = getStore('ExamProvince')
                            if (!province) {
                                this.$message.error('无省份数据！')
                                return false
                            }
                            province = JSON.parse(province)

                            if (userInfo.UserScore) {
                                
                                if (userInfo.UserScore.ExamProvinceId) {
                                    let obj = province.find(item => item.ProvinceId === userInfo.UserScore.ExamProvinceId)
                                    
                                    if (obj) {
                                        setStore('OrgProvince', obj)
                                    } else {
                                        this.$message.error('ExamProvinceId未更新！')
                                    }
                                }
                            }

                            // this.$router.replace("/personal");
                            API.User.CheckUserStudentComplate().then(res => {
                                if (res.code==0) {
                                    if (res.data.IsNeedComplateBaseInfo) {
                                        this.$router.replace("/personal");
                                    } else if (res.data.IsNeedComplateScore) {
                                        this.$router.replace("/examinee");
                                    } else {
                                        this.$router.replace("/");
                                    }
                                } else {
                                    this.$router.replace("/");
                                }
                            })
                        })
                    }
                    else if (res.Code==116) {
                        this.userinfoshow()
                    }
                    else {
                        this.verifytips(res.Message);
                    }
                });
            }
        },
        //手机验证码 登录 / 注册
        login_tel() {
            if (!this.tipsshow) {
                if (!this.telnumber) {
                    this.verifytips("请输入手机号！"); return false;
                }
                else {
                    let str = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if (str.test(this.telnumber.trim()) == false) {
                        this.verifytips("手机号格式不正确！"); return false;
                    }
                }
                if (!this.telcode) {
                    this.verifytips("请输入验证码！"); return false;
                }
                if (!this.agreement) {
                    this.verifytips("请阅读《用户协议》和《隐私政策》并勾选接受"); return false;
                }
                //登录
                API.Web.ValidateCodeLoginOrRegister({ TerminalType: 0, ValidateType: 0, Mobile: this.telnumber, ValidateCode: this.telcode }).then(res => {
                    if (res.Code === 0) {
                        setStore("LoginData", res.Data);

                        this.$store.dispatch('user/getStudentInfo').then(userInfo => {

                            removeStore('tempScoreInfo')

                            let province = getStore('ExamProvince')
                            if (!province) {
                                this.$message.error('无省份数据！')
                                return false
                            }
                            province = JSON.parse(province)

                            if (userInfo.UserScore) {
                                
                                if (userInfo.UserScore.ExamProvinceId) {
                                    let obj = province.find(item => item.ProvinceId === userInfo.UserScore.ExamProvinceId)
                                    
                                    if (obj) {
                                        setStore('OrgProvince', obj)
                                    } else {
                                        this.$message.error('ExamProvinceId未更新！')
                                    }
                                }
                            }

                            
                            // this.$router.replace("/personal");
                            API.User.CheckUserStudentComplate().then(res => {
                                if (res.code==0) {
                                    if (res.data.IsNeedComplateBaseInfo) {
                                        this.$router.replace("/personal");
                                    } else if (res.data.IsNeedComplateScore) {
                                        this.$router.replace("/examinee");
                                    } else {
                                        this.$router.replace("/");
                                    }
                                } else {
                                    this.$router.replace("/");
                                }
                            })
                        })

                    }
                    else {
                        this.verifytips(res.Message);
                    }
                });
            }
        },
        //登录注册验证提示
        verifytips(txt) {
            this.tipsshow = true;
            this.tipsshowtxt = txt;
            this.tipsshowstyle = "ani_show";
            let st = setTimeout(() => {
                this.tipsshowtxt = "";
                this.tipsshowstyle = "";
                this.tipsshow = false;
                clearTimeout(st);
            }, 1500);
        },
        //用户协议
        useragreement(){
            this.layerclass='layershow';
            this.layerboxclass="layerboxshow";
            this.agreementshow=true;
            API.Web.GetContentDetail({contentKey:"Agreement_User"}).then(res => {
                if(res.Code===0&&res.Data!=null){
                    this.agreementhtml=res.Data.RichTextContent;
                }
            });
        },
        agreementhide() {
            this.layerclass = 'layerhide';
            this.layerboxclass = "layerboxhide";
            let st = setTimeout(() => {
                this.agreementshow = false;
                clearTimeout(st);
            }, 300);
        },
        //隐私政策
        privacypolicy(){
            this.layerclass='layershow';
            this.layerboxclass="layerboxshow";
            this.privacyshow=true;
            API.Web.GetContentDetail({contentKey:"Agreement_Privacy"}).then(res => {
                if(res.Code===0&&res.Data!=null){
                    this.privacyhtml=res.Data.RichTextContent;
                }
            });
        },
        privacyhide() {
            this.layerclass = 'layerhide';
            this.layerboxclass = "layerboxhide";
            let st = setTimeout(() => {
                this.privacyshow = false;
                clearTimeout(st);
            }, 300);
        },
        //找回密码
        forgetpwd() {
            this.$refs.refforgetpwd.layerclass = 'layershow';
            this.$refs.refforgetpwd.layerboxclass = "layerboxshow";
            this.$refs.refforgetpwd.forgetpwdshow = true;
        },
        //微信登录
        wechatloginbtn() {
            if (!this.tipsshow) {
                this.verifytips("抱歉，微信登录方式暂未开放！");
            }
        },
    }
}
</script>
<style lang="less" scoped>
.loginbox {
    width: 100%;
    height: 660px;
    background: url(../../src/assets/images/login_back1.jpg) no-repeat center top / cover;

    .formbox {
        max-width: 1420px;
        height: 100%;
        position: relative;
        margin: auto;

        .tipsbox {
            position: absolute;
            width: 440px;
            height: 44px;
            line-height: 44px;
            color: #F56C6C;
            text-indent: 10px;
            font-size: 14px;
            top: 0px;
            right: 49px;
            z-index: 2;
            opacity: 0;
            background-color: #fef0f0;
            border: 1px solid #fde2e2;
            border-radius: 5px;

            span {
                width: 12px;
                height: 12px;
                display: block;
                float: left;
                margin-left: 15px;
                margin-top: 16.5px;
                background-color: #F56C6C;
                line-height: 5.5px;
                text-indent: 3.5px;
                border-radius: 10px;

                i {
                    font-size: 6px;
                    color: #fff;
                }
            }
        }

        .tipsbox.ani_show {
            animation: ani_show 1.5s ease-out 0s 1;
            -webkit-animation: ani_show 1.5s ease-out 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;

            @-webkit-keyframes ani_show {
                0% {
                    top: 0px;
                    opacity: 0;
                }

                10% {
                    top: 120px;
                    opacity: 1;
                }

                90% {
                    top: 120px;
                    opacity: 1;
                }

                100% {
                    top: 0px;
                    opacity: 0;
                }
            }

            @keyframes ani_show {
                0% {
                    top: 0px;
                    opacity: 0;
                }

                10% {
                    top: 120px;
                    opacity: 1;
                }

                90% {
                    top: 120px;
                    opacity: 1;
                }

                100% {
                    top: 0px;
                    opacity: 0;
                }
            }
        }

        .tabbox {
            position: absolute;
            right: 20px;
            top: 75px;
            width: 500px;
            height: 500px;
            border: 1px solid #ddd;
            // border-radius: 5px;
            background-color: #fff;
            overflow: hidden;
            .otherlogin {
                width: 100%;
                text-align: center;
                margin-top: 55px;
                .otit {
                    width: 100%;
                    height: 89px;
                    position: relative;
                    border-top: 1px solid #e0e0e0;
                    user-select: none;
                    background:url(../assets/images/trophy.png) no-repeat center center / 100%;
                    background-color: rgba(var(--themecolor),0.9);
                }
            }

            .inputbox {
                margin: 20px 30px 0;

                .ibox {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    margin-bottom: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        line-height: 1;
                        line-height: 48px;
                        text-indent: 15px;
                        border: 1px solid #dddddd;
                        color: #666;
                        font-size: 14px;
                    }

                    input:focus {
                        border: 1px solid rgb(var(--themecolor));
                    }

                    i {
                        position: absolute;
                        width: 40px;
                        height: 48px;
                        right: 1px;
                        top: 1px;
                        line-height: 50px;
                        text-align: center;
                        color: #999;
                        font-size: 18px;
                        border-radius: 5px;
                        user-select: none;
                    }

                    i:hover {
                        cursor: pointer;
                        opacity: 0.6;
                        color: rgb(var(--themecolor));
                    }

                    .code {
                        width: 320px;
                    }

                    .codebtn {
                        width: 100px;
                        height: 50px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        line-height: 50px;
                        text-align: center;
                        color: #666;
                        font-size: 14px;
                        border: 1px solid #d8d8d8;
                        user-select: none;
                    }

                    .codebtn:hover {
                        cursor: pointer;
                        color: rgb(var(--themecolor));
                    }

                    .submitbtn {
                        margin-top: 40px;
                        color: #fff;
                        height: 50px;
                        line-height: 50px;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        background-color: rgb(var(--themecolor));
                        opacity: 0.75;
                        border-radius: 5px;
                        user-select: none;
                        transition: opacity 0.2s;
                        -webkit-transition: opacity 0.2s;
                    }

                    .submitbtn:hover {
                        opacity: 0.9;
                        cursor: pointer;
                    }
                }
            }

            .txtbox {
                margin: 0px 30px;
                height: 30px;

                span.txt {
                    display: block;
                    height: 30px;
                    line-height: 30px;
                    color: #666;
                    font-size: 14px;

                    .getpwd {
                        float: right;
                        user-select: none;
                    }

                    .getpwd:hover {
                        color: rgb(var(--themecolor));
                        opacity: 0.75;
                        cursor: pointer;
                    }
                }

                span.toreg {
                    float: left;

                    a.xy {
                        color: rgb(var(--themecolor));
                        opacity: 0.75;
                        user-select: none;
                    }

                    a.xy:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.layer_agreement.layershow {
    animation: layershow 0.3s ease 0s 1;
    -webkit-animation: layershow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layershow {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes layershow {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}

.layer_agreement.layerhide {
    animation: layerhide 0.3s ease 0s 1;
    -webkit-animation: layerhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerhide {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes layerhide {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

.layer_agreement {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.5);

    .layerbox.layerboxshow {
        animation: layerboxshow 0.3s ease 0s 1;
        -webkit-animation: layerboxshow 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        @-webkit-keyframes layerboxshow {
            from {
                top: 0px;
            }

            to {
                top: 40px;
            }
        }

        @keyframes layerboxshow {
            from {
                top: 0px;
            }

            to {
                top: 40px;
            }
        }
    }

    .layerbox.layerboxhide {
        animation: layerboxhide 0.3s ease 0s 1;
        -webkit-animation: layerboxhide 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        @-webkit-keyframes layerboxhide {
            from {
                top: 40px;
            }

            to {
                top: 0px;
            }
        }

        @keyframes layerboxhide {
            from {
                top: 40px;
            }

            to {
                top: 0px;
            }
        }
    }

    .layerbox {
        width: 700px;
        height: calc(100% - 80px);
        position: absolute;
        top: 0px;
        left: calc(50% - 350px);
        background-color: #fff;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        .layercon {
            width: 100%;
            height: 100%;
            position: relative;

            .tipsbox {
                position: absolute;
                width: 440px;
                height: 44px;
                line-height: 44px;
                color: #F56C6C;
                text-indent: 10px;
                font-size: 14px;
                top: 0px;
                left: 130px;
                z-index: 2;
                opacity: 0;
                background-color: #fef0f0;
                border: 1px solid #fde2e2;
                border-radius: 5px;

                span {
                    width: 12px;
                    height: 12px;
                    display: block;
                    float: left;
                    margin-left: 15px;
                    margin-top: 16.5px;
                    background-color: #F56C6C;
                    line-height: 5.5px;
                    text-indent: 3.5px;
                    border-radius: 10px;

                    i {
                        font-size: 6px;
                        color: #fff;
                    }
                }
            }

            .scrollbox {
                margin: 0px 30px;
                height: calc(100% - 120px);
                overflow-x: hidden;
                .info_box{
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    margin: 10px 0;
                    overflow: hidden;
                    background-color: #fff;
                    .info_title{
                        width: 100%;
                        text-indent: 25px;
                        color: #777;
                        font-size: 14px;
                        line-height: 40px;
                        height: 40px;
                        position: relative;
                        background-color: #f8f8f8;
                        i{
                            width: 3px;
                            height: 18px;
                            display: block;
                            position: absolute;
                            left: 10px;
                            top: 11px;
                            z-index: 1;
                            background-color: rgba(var(--themecolor),0.6);
                        }
                    }
                    .info_input{
                        margin: 10px 0;
                        input{
                            width: calc(100% - 40px);
                            display: block;
                            margin: 0 20px;
                            height: 40px;
                            line-height: 40px;
                            line-height: 1;
                            text-indent: 10px;
                            color: #666;
                            font-size: 14px;
                            border: 1px solid #ddd;
                            border-radius: 3px;
                        }
                        .code{
                            width: calc(100% - 162px);
                            float: left;
                        }
                        .cbtn {
                            width: 100px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            color: #666;
                            font-size: 14px;
                            border: 1px solid #d8d8d8;
                            user-select: none;
                            float: right;
                            margin-right: 18px;
                            background-color: #f1f1f1;
                            border-radius: 3px;
                        }

                        .cbtn:hover {
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                        }
                    }
                    .tips{
                        color: #757575;
                        font-size: 12px;
                        i {
                            margin-right: 5px;
                        }
                    }
                    .info_form{
                        margin-right: 20px;
                    }
                }
                
                .content {
                    color: #666;
                    font-size: 14px;
                    line-height: 26px;
                    text-indent: 28px;
                }

                .inputbox {
                    margin: 50px 50px 0;

                    i.success {
                        color: #159405;
                        font-size: 100px;
                        display: block;
                        text-align: center;
                        padding-top: 20px;
                    }

                    .successtxt {
                        color: #666;
                        font-size: 18px;
                        text-align: center;
                        margin-top: 10px;
                    }

                    .marktxt {
                        color: #888;
                        font-size: 14px;
                        margin-top: 10px;
                        text-align: center;
                    }

                    .ibox {
                        width: 100%;
                        height: 50px;
                        position: relative;
                        margin-bottom: 20px;

                        input {
                            width: 100%;
                            height: 100%;
                            line-height: 1;
                            line-height: 48px;
                            text-indent: 15px;
                            border: 1px solid #dddddd;
                            color: #666;
                            font-size: 14px;
                        }

                        input:focus {
                            border: 1px solid rgb(var(--themecolor));
                        }

                        i {
                            position: absolute;
                            width: 40px;
                            height: 48px;
                            right: 1px;
                            top: 1px;
                            line-height: 50px;
                            text-align: center;
                            color: #999;
                            font-size: 18px;
                            background-color: #fff;
                            border-radius: 5px;
                        }

                        i:hover {
                            cursor: pointer;
                            opacity: 0.6;
                            color: rgb(var(--themecolor));
                        }

                        .code {
                            width: 420px;
                        }

                        .codebtn {
                            width: 100px;
                            height: 50px;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            line-height: 50px;
                            text-align: center;
                            color: #666;
                            font-size: 14px;
                            border: 1px solid #d8d8d8;
                            user-select: none;
                        }

                        .codebtn:hover {
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                        }

                        .submitbtn {
                            margin-top: 40px;
                            color: #fff;
                            height: 50px;
                            line-height: 50px;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                            background-color: rgb(var(--themecolor));
                            opacity: 0.75;
                            border-radius: 5px;
                            transition: opacity 0.2s;
                            -webkit-transition: opacity 0.2s;
                        }

                        .submitbtn.b {
                            background-color: #ddd;
                            color: #888;
                        }

                        .submitbtn:hover {
                            opacity: 0.9;
                            cursor: pointer;
                        }
                    }
                }
            }

            .tit {
                text-align: center;
                height: 60px;
                line-height: 60px;
                color: #333;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 1px;
                border-bottom: 1px solid #e8e9e9;
            }

            .back {
                width: 100%;
                height: 44px;
                line-height: 44px;
                position: absolute;
                bottom: 0;
                left: 0px;
                z-index: 66;
                background-color: #ddd;
                color: #666;
                font-size: 14px;
                letter-spacing: 2px;
                text-align: center;
                user-select: none;
            }

            .back:hover {
                cursor: pointer;
                opacity: 0.85;
                color: rgb(var(--themecolor));
            }
            .btn1{
                width: 50%;
                height: 50px;
                line-height: 50px;
                color: #666;
                background-color: #eeeded;
                font-size: 14px;
                text-align: center;
                float: left;
                margin-top: 10px;
            }
            .btn1:hover{
                cursor: pointer;
                background-color: #e7e7e7;
            }
            .btn2{
                width: 50%;
                height: 50px;
                line-height: 50px;
                color: #fff;
                background-color: rgba(var(--themecolor),0.8);
                font-size: 14px;
                text-align: center;
                float: right;
                margin-top: 10px;
            }
            .btn2:hover{
                cursor: pointer;
                background-color: rgba(var(--themecolor),1);
            }
        }
    }
}
</style>
<style>
.loginbox .formbox .tabbox .el-tabs__nav {
    width: 100%;
    height: 60px;
    border: 0;
}

.loginbox .formbox .tabbox .el-tabs__item {
    width: 50%;
    padding: 0;
    text-align: center;
    height: 100%;
    line-height: 58px;
    color: #666;
    font-size: 15px;
    user-select: none;
}

.loginbox .formbox .tabbox .el-tabs__item.is-active {
    color: rgb(var(--themecolor));
}

.loginbox .formbox .tabbox .el-tabs__item.is-focus {
    box-shadow: none;
}

.loginbox .formbox .el-checkbox:hover {
    opacity: 0.85;
}

.loginbox .formbox .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}

.loginbox .formbox .el-checkbox__inner {
    border-color: rgb(var(--themecolor));
    opacity: 0.8;
}

.loginbox .formbox .el-checkbox__inner:hover {
    border-color: rgb(var(--themecolor));
}

.loginbox .formbox .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #666;
}

.layer_agreement .layerbox .layercon .el-scrollbar__wrap {
    overflow-x: hidden;
}

.layer_agreement .layerbox .layercon .el-step__head.is-finish,
.layer_agreement .layerbox .layercon .el-step__title.is-finish {
    color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}

.layer_agreement .layerbox .layercon .el-step__head.is-process,
.layer_agreement .layerbox .layercon .el-step__title.is-process {
    color: #666;
    border-color: #666;
}
.layer_agreement .layerbox .el-form .el-form-item .el-radio__input.is-checked .el-radio__inner{
    border-color: rgb(var(--themecolor));
    background: rgb(var(--themecolor));
}
.layer_agreement .layerbox .el-form .el-form-item .el-radio__input .el-radio__inner:hover{
    border: 1px solid #DCDFE6;
}
.layer_agreement .layerbox .el-form .el-form-item .el-radio__input.is-checked + .el-radio__label{
    color: rgb(var(--themecolor));
}
.layer_agreement .layerbox .el-form .el-form-item .el-input__inner:focus{
    border-color: #ddd;
}
.layer_agreement .layerbox .el-cascader .el-input .el-input__inner:focus,.layer_agreement .layerbox .el-cascader .el-input.is-focus .el-input__inner{
    border-color: rgb(var(--themecolor));
}
.layer_agreement .layerbox .el-range-editor.is-active, .el-range-editor.is-active:hover, .layer_agreement .layerbox .el-select .el-input.is-focus .el-input__inner{
    border-color: rgb(var(--themecolor));
}
.el-year-table td .cell:hover, .el-year-table td.current:not(.disabled) .cell{
    color: rgb(var(--themecolor));
}
.el-date-picker__header-label.active, .el-date-picker__header-label:hover{
    color: rgb(var(--themecolor));
}
.el-picker-panel__icon-btn:hover{
    color: rgb(var(--themecolor));
}
.layer_agreement .layerbox .el-checkbox__input.is-checked .el-checkbox__inner, .layer_agreement .layerbox .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}
.layer_agreement .layerbox .el-checkbox__inner:hover,.layer_agreement .layerbox .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: rgb(var(--themecolor));
}
.layer_agreement .layerbox .el-checkbox__input.is-checked + .el-checkbox__label{
    color: rgb(var(--themecolor));
}
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
    color: rgb(var(--themecolor));
}
.el-select-dropdown__item.selected{
    color: rgb(var(--themecolor));
}
.el-year-table td.today .cell{
    color: #606266;
    font-weight: normal;
}
</style>